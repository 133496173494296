
import {
  LOADED_DEPENDENCIES,
  UPDATE_NAVIGATION,
} from 'state/actions'
import { getContent } from 'global-content/content'
import { getConfigOptions } from 'global-content/config'
import { getLanguage } from 'utils'
import { unversionedStorage } from 'utils/storage'
import first from 'lodash/first'

export const LOCALSTORAGE_NAVCONTEXT_KEY = `lastNavContext`

const initialState = {
  breadcrumbs: [],
  currentPage: {},
  navContext: unversionedStorage.get(LOCALSTORAGE_NAVCONTEXT_KEY),
  secondaryContext: ``,
}

const navigation = (state = initialState, action) => {
  switch (action.type) {
  case LOADED_DEPENDENCIES: {
    return {
      ...state,
      navContext: getNavContext(state.breadcrumbs, state.navContext),
    }
  }
  case UPDATE_NAVIGATION: {
    const {
      breadcrumbs,
      currentPage,
      navContext,
      secondaryContext,
    } = action.payload

    return {
      breadcrumbs,
      currentPage,
      navContext: getNavContext(breadcrumbs, navContext ? navContext : state.navContext),
      secondaryContext,
    }
  }
  default:
    return state
  }
}

function getNavContext(breadcrumbs, current) {
  const navContext = getActualNavContext(breadcrumbs, current)

  if (getConfigOptions(`navigation.rememberNavContext`)) {
    saveNavContext(navContext)
  }

  return navContext
}

function getActualNavContext(breadcrumbs, current) {
  const navTree = getContent(`navTree`, getLanguage())
  const navTreeL1Keys = Object.keys(navTree.l1)
  let navContext = null
  const startingNavContext = getConfigOptions(`navigation.startingNavContext`)
  const navContextMap = getConfigOptions(`navigation.navContextMap`)

  function selectValidNavContext() {
    if (startingNavContext >= 0) {
      return navTreeL1Keys[startingNavContext]
    }
    return first(navTreeL1Keys)
  }

  if (navTreeL1Keys.includes(breadcrumbs[0]?.slug)) {
    navContext = breadcrumbs[0].slug
  } else if (current) {
    // Note: There might be a case where "current" is invalid because of content error
    // So check that current is valid before redirecting to it. If its not valid, then
    // we fallback to the "startingNavContext" index from the config.
    if (navTreeL1Keys.includes(current)) {
      navContext = current
    } else {
      navContext = selectValidNavContext()
    }
  } else if (startingNavContext >= 0) {
    navContext = selectValidNavContext()
  }

  if (navContextMap) {
    if(breadcrumbs.length == 0){
      navContext = navContextMap[`*`]
    }
    else if (navContextMap[navContext]) {
      navContext = navContextMap[navContext]
    } else {
      navContext = navContextMap[`*`]
    }
  }

  return navContext
}

// ### side effect in the reducer ###
// doesn't have any affect on an application after it has loaded
// so is this a problem?
// store.subscribe() seems like a rubbish way of handling side-effects
// as it runs for everything
// what is the best way of handling redux side effects?

function saveNavContext(navContext) {
  unversionedStorage.set(LOCALSTORAGE_NAVCONTEXT_KEY, navContext)
}

export default navigation
