import React, { useEffect } from 'react'
import classnames from 'classnames'

import { getComponentOptions } from 'global-content/components'
import { LinkText } from 'components/LinkText'
import { Layout } from 'components/Layout'
import { HtmlText } from 'components/HtmlText'

import styles from './SideNavLink.css'

export const SideNavLink = ({
  active,
  button,
  children,
  className,
  fontSize,
  name,
  onClick,
  id,
  to,
  validateLinkFn,
  value,
  isPrivacyPolicy,
}) => {
  const {
    config: {
      activeState,
      hoverState,
    },
    layout = {},
  } = getComponentOptions(`sideNavLink`)

  const [isValid, setIsValid] = React.useState(false)

  useEffect(() => {
    async function checkValidation() {
      if (validateLinkFn) {
        const result = await validateLinkFn()
        setIsValid(Boolean(result))
      } else {
        // fallback to true when there is no validateFn
        setIsValid(true)
      }
    }
    checkValidation()
  }, [])

  const hasValue = Boolean(value)
  const shouldShow = Boolean(hasValue && isValid)
  if (shouldShow === false) {
    return null
  }

  return (
    <Layout
      className={styles.container}
      layout={{
        marginBottom: layout.marginBottom,
      }}
    >
      <LinkText
        active={active}
        activeState={activeState}
        aria-label={name}
        button={button}
        className={classnames(className, {
          'optanon-show-settings': isPrivacyPolicy,
        })}
        hoverState={hoverState}
        id={id}
        name={name}
        onClick={onClick}
        style={{
          fontSize,
        }}
        to={to}
        value={value}
      >
        <HtmlText html={value}/>
      </LinkText>
      {children}
    </Layout>
  )
}
