import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect as RedirectRoute } from 'react-router-dom'
import get from 'lodash/get'

import { getSourceFile } from 'utils/getSourceFile'
import { useGlobalContent } from 'hooks/useGlobalContent'
import { getMetaOptions, getConfigOptions } from 'global-content/config'
import { Scaffold } from 'components/Scaffold'
import { AccountPaymentMethods } from 'pages/AccountPaymentMethods'
import { AccountCreation } from 'pages/AccountCreation'
import { AccountAddresses } from 'pages/AccountAddresses'
import { AccountHome } from 'pages/AccountHome'
import { AccountForgotPassword } from 'pages/AccountForgotPassword'
import { AccountNewPassword } from 'pages/AccountNewPassword'
import { AccountVerification } from 'pages/AccountVerification'
import { AccountSignIn } from 'pages/AccountSignIn'
import { Brand } from 'pages/Brand'
import { Brands } from 'pages/Brands'
import { Complete } from 'pages/Complete'
import { CustomPage } from 'pages/CustomPage'
import { Faceted } from 'pages/Faceted'
import { Favorites } from 'pages/Favorites'
import { Homepage } from 'pages/Home'
import { NotFound } from 'pages/NotFound'
import { OrderDetails } from 'pages/OrderDetails'
import { OrderHistory } from 'pages/OrderHistory'
import { OrderStatus } from 'pages/OrderStatus'
import { Redirect } from 'pages/Redirect'
import { SearchResults } from 'pages/SearchResults'
import { Tracking } from 'pages/Tracking'
import { RecreateCart } from 'pages/RecreateCart'
import { Product } from 'pages/Product'
import { Checkout } from 'pages/CheckoutTemp'

import {
  ACCOUNT_ROUTE,
  ACCOUNT_ADDRESSES_ROUTE,
  ACCOUNT_CREATE_ROUTE,
  ACCOUNT_PAYMENT_METHODS_ROUTE,
  ACCOUNT_VERIFICATION_ROUTE,
  ACCOUNT_SIGNIN_ROUTE,
  ACCOUNT_FORGOTTEN_PASSWORD_ROUTE,
  ACCOUNT_NEW_PASSWORD_ROUTE,
  CHECKOUT_ROUTE,
  COMPLETE_ROUTE,
  FAVORITES_ROUTE,
  PRODUCT_ROUTE,
  RECREATE_CART_ROUTE,
  REDIRECT_ROUTE,
  SEARCH_ROUTE,
  TRACKING_ROUTE,
  ORDER_HISTORY_ROUTE,
  ORDER_DETAILS_ROUTE,
  ORDER_STATUS_ROUTE,
} from './fixtures'
import {
  getBrandRoute,
  getBrandsIndex,
} from './utils'

export const Router = () => {
  const activeLanguage = useSelector(state => state.language.active)
  const activeNavContext = useSelector(state => state.navigation.navContext)
  const navTree = useGlobalContent(`navTree`)
  const countryFolder = getMetaOptions(`countryFolder`)
  const countryCode = getMetaOptions(`country.code`).toLowerCase()
  const basename = countryFolder
    ? `${countryFolder}/${activeLanguage}`
    : `/${activeLanguage}-${countryCode}`

  return (
    <BrowserRouter
      basename={basename}
      key={activeLanguage}
    >
      {/* Scaffold also handles side effects for page switching */}
      <Scaffold
        basename={basename}
      >
        <Switch>
          <Route
            exact
            path="/"
            render={renderHomePage}
          />
          {renderClpRoutes()}
          {renderAccountPages()}
          <Route
            component={Redirect}
            exact
            path={REDIRECT_ROUTE}
          />
          <Route
            component={Complete}
            exact
            path={COMPLETE_ROUTE}
          />
          <Route
            component={Tracking}
            exact
            path={TRACKING_ROUTE}
          />
          <Route
            component={Product}
            exact
            path={PRODUCT_ROUTE}
          />
          <Route
            component={Checkout}
            exact
            path={CHECKOUT_ROUTE}
          />
          <Route
            component={RecreateCart}
            exact
            path={RECREATE_CART_ROUTE}
          />
          <Route
            component={SearchResults}
            exact
            path={SEARCH_ROUTE}
          />
          <Route
            component={Favorites}
            exact
            path={FAVORITES_ROUTE}
          />
          <Route
            component={OrderHistory}
            exact
            key={ORDER_HISTORY_ROUTE}
            path={ORDER_HISTORY_ROUTE}
          />
          <Route
            component={OrderStatus}
            exact
            path={ORDER_STATUS_ROUTE}
          />
          <Route
            component={OrderDetails}
            exact
            path={ORDER_DETAILS_ROUTE}
          />
          <Route
            component={Brand}
            exact
            path={getBrandRoute()}
          />
          <Route
            component={Brands}
            exact
            path={getBrandsIndex()}
          />
          <Route render={navTreeSearch} />
        </Switch>
      </Scaffold>
    </BrowserRouter>
  )

  function renderClpRoutes() {
    return [...window.$content.routes.list].map(route => {
      const source = getSourceFile(route.split(`/`).join(`_`), activeLanguage)
      const folder = `/data/clp`

      return (
        <Route
          exact
          key={route}
          path={`/${route}`}
          render={() => (
            <CustomPage
              cacheName={`/${route}`}
              folder={folder}
              source={source}
              // cacheName is so we can easily reference its data
              // in the navigation actions using the clp list
            />
          )}
        />
      )
    })
  }

  function renderHomePage() {
    const { startingNavContext: startingNavContextIndex } = getConfigOptions(`navigation`) || {}
    const isUsingNavContext = startingNavContextIndex > -1
    if (isUsingNavContext && activeNavContext) {
      let startingNavContext = Object.keys(navTree.l1)[
        startingNavContextIndex
      ]

      const navContextMap = getConfigOptions(`navigation.navContextMap`)
      if(navContextMap) {
        if (navContextMap[startingNavContext]) {
          startingNavContext = navContextMap[startingNavContext]
        } else {
          startingNavContext = navContextMap[`*`]
        }
      }

      const isActiveNavContextValid = Boolean(get(navTree, `l1.${activeNavContext}`, false))
      if (activeNavContext !== startingNavContext && isActiveNavContextValid) {
        return <RedirectRoute to={navTree.l1[activeNavContext].path} />
      }
    }

    return <Homepage />
  }

  function renderAccountPages() {
    if (getMetaOptions(`integrations.cognito.enabled`)) {
      return [
        <Route
          component={AccountHome}
          exact
          key={ACCOUNT_ROUTE}
          path={ACCOUNT_ROUTE}
        />,
        <Route
          component={AccountAddresses}
          exact
          key={ACCOUNT_ADDRESSES_ROUTE}
          path={ACCOUNT_ADDRESSES_ROUTE}
        />,
        <Route
          component={AccountPaymentMethods}
          exact
          key={ACCOUNT_PAYMENT_METHODS_ROUTE}
          path={ACCOUNT_PAYMENT_METHODS_ROUTE}
        />,
        <Route
          component={AccountCreation}
          exact
          key={ACCOUNT_CREATE_ROUTE}
          path={ACCOUNT_CREATE_ROUTE}
        />,
        <Route
          component={AccountVerification}
          exact
          key={ACCOUNT_VERIFICATION_ROUTE}
          path={ACCOUNT_VERIFICATION_ROUTE}
        />,
        <Route
          component={AccountSignIn}
          exact
          key={ACCOUNT_SIGNIN_ROUTE}
          path={ACCOUNT_SIGNIN_ROUTE}
        />,
        <Route
          component={AccountForgotPassword}
          exact
          key={ACCOUNT_FORGOTTEN_PASSWORD_ROUTE}
          path={ACCOUNT_FORGOTTEN_PASSWORD_ROUTE}
        />,
        <Route
          component={AccountNewPassword}
          exact
          key={ACCOUNT_NEW_PASSWORD_ROUTE}
          path={ACCOUNT_NEW_PASSWORD_ROUTE}
        />,
      ]
    }
  }

  function navTreeSearch({ location }) {
    if (isInNavTree(location.pathname)) {
      return (
        <Faceted />
      )
    }

    return (
      <NotFound />
    )
  }

  function isInNavTree(pathname) {
    let isValid = false
    const levels = pathname.split(`/`).slice(1)

    walkTree(navTree.l1)

    function walkTree(branch, i = 0) {
      const key = levels[i]

      if (branch && branch[key]) {
        isValid = true
        const l = `l${i + 2}`
        const childBranch = branch[key][l]

        if (levels[i + 1]) {
          walkTree(childBranch, i + 1)
        }
      } else {
        isValid = false
      }
    }

    return isValid
  }
}
