import { getLocale, getLanguage } from 'utils'
import { unversionedStorage } from 'utils/storage'
import { getMetaOptions } from 'global-content/config'
import {
  CHANGE_LANGUAGE,
  LOADED_DEPENDENCIES,
  SET_LANG_DIR,
} from 'state/actions'

const initialState = {
  active: getLanguage() || `en`,
  locale: undefined,
  langDir: `ltr`,
}

const language = (state = initialState, action) => {
  switch (action.type) {
  case LOADED_DEPENDENCIES: {
    const lang = getLanguage() || getMetaOptions(`defaultLanguage`)
    updateDomLanguage(lang)

    return {
      ...state,
      active: lang,
      locale: getLocale(lang),
    }
  }

  case SET_LANG_DIR:
    return {
      ...state,
      langDir: action.payload,
    }

  case CHANGE_LANGUAGE: {
    const lang = action.payload
    updateUrl(state.active, lang)
    updateDomLanguage(lang)
    updateStorageLanguage(lang)

    return {
      ...state,
      active: action.payload,
      locale: getLocale(lang),
    }
  }
  default:
    return state
  }
}

export function getNewLangUrl(current, replace) {
  const updatedPathname = getMetaOptions(`countryFolder`)
    ? window.location.pathname.replace(`${getMetaOptions(`countryFolder`)}/${current}`, `${getMetaOptions(`countryFolder`)}/${replace}`)
    : window.location.pathname.replace(`/${current}`, `/${replace}`)
  const redirectUrl = `${window.location.origin}${updatedPathname}`
  return redirectUrl
}

function updateUrl(current, replace) {
  const redirectUrl = getNewLangUrl(current, replace)
  window.history.pushState({}, ``, redirectUrl)
}

function updateDomLanguage(lang) {
  // https://www.w3schools.com/tags/ref_language_codes.asp
  document.querySelector(`html`).setAttribute(`lang`, lang)
  document.querySelector(`html`).className = lang
}

function updateStorageLanguage(newLang) {
  const clientTag = getMetaOptions(`clientTag`)
  const country = getMetaOptions(`country.code`).toLowerCase()
  const preChosenLanguages = unversionedStorage.get(`chosenLanguages`)

  const chosenLanguage = {
    [`${clientTag}-${country}`]: newLang,
  }
  let chosenLanguages
  if (preChosenLanguages) {
    chosenLanguages = {
      ...preChosenLanguages,
      ...chosenLanguage,
    }
  } else {
    chosenLanguages = chosenLanguage
  }

  unversionedStorage.set(`chosenLanguages`, chosenLanguages)
}

export default language
