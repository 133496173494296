import { getMetaOptions } from 'global-content/config'
import { storage } from './storage'

// NOTE:
// Keep this utils file as 'pure' as possible.
// If you are writing a utils function that requires
// another src file (not npm package), put it in its own file

const desiredIntlFractionDigitsMap = {
  AU: 2,
  CN: 0, // default is 2 | we want 0
  HK: 0, // default is 2 | we want 0
  JP: 0,
  KR: 0,
  MX: 0, // default is 2 | we want 0
  MY: 0,
  NZ: 2,
  TR: 2,
  RU: 0, // default is 2 | we want 0
  SG: 2,
  TW: 0, // default is 2 | we want 0
  US: 2,
  AE: 0,
  BH: 0,
  SA: 0,
  QA: 0,
  KW: 0,
  HU: 0,
}

const tempIntlSymbolReplaceMap = {
  SG: `$`, // $S
  JP: `￥`, // ¥
  AU: `$`, // A$
  MX: `$`, // MX$
  TW: `$`, // NT$
  CA: `$`, // CA$
}

const localeReplaceMap = {
  'ar-AE': `en-AE`,
  'ar-BH': `en-BH`,
  'ar-SA': `en-SA`,
  'ar-QA': `en-QA`,
  'ar-KW': `en-KW`,
}

export function getLocale(lang) {
  return `${lang}-${getMetaOptions(`country.code`)}`
}

function strikeThrough(text, strikeThroughClass) {
  return `<span class="${strikeThroughClass}">${text}</span>`
}

function formatter(value, locale, code, showCurrencySign, countryCode) {
  return new Intl.NumberFormat(locale, {
    style: `currency`,
    currency: code,
    currencyDisplay: showCurrencySign ? `symbol` : `code`,
    maximumFractionDigits: desiredIntlFractionDigitsMap[countryCode],
  }).format(value)
}

export function discountFormat(
  value,
  showCurrencySign = true,
  isDiscounted = false,
  isFree = false,
  isFreeText = ``,
  shippingCost = 0,
  strikeThroughClass,
) {
  if (isDiscounted) {
    const defaultLanguage = getMetaOptions(`defaultLanguage`)
    const locale = getLocale(defaultLanguage)
    const { code } = getMetaOptions(`currency`)
    const countryCode = getMetaOptions(`country.code`)
    const shippingCostFormatted = formatter(shippingCost, locale, code, showCurrencySign, countryCode)
    const isDiscountedAdditionalText = isFree ? isFreeText : shippingCostFormatted
    return `${strikeThrough(value, strikeThroughClass)} ${isDiscountedAdditionalText}`
  }
  return value
}

export function currencyFormatted(
  value,
  showCurrencySign = true,
) {
  const countryCode = getMetaOptions(`country.code`)
  const defaultLanguage = getMetaOptions(`defaultLanguage`)
  let locale = getLocale(defaultLanguage)
  const localeRemap = localeReplaceMap[locale]

  if (localeRemap) {
    locale = localeRemap
  }

  const {
    code,
    symbol,
  } = getMetaOptions(`currency`)

  // https://www.six-group.com/en/services/search.html?as_q=currency+codes&division=all
  const formatted = formatter(value, locale, code, showCurrencySign, countryCode)

  if (!showCurrencySign) {
    return formatted.replace(code, ``).trim()
  }

  if (tempIntlSymbolReplaceMap[countryCode]) {
    return formatted.replace(tempIntlSymbolReplaceMap[countryCode], symbol)
  }

  return formatted
}

export const isSingapore = () => {
  return getMetaOptions(`country.code`).toLowerCase() === `sg`
}

export function adyenDeviceFingerprint(inputElementId) {
  // TODO: find a way to respond to when it is actually ready rather than a timeout
  // TODO: call this from app.js after move to react rather than header.js

  // this magic number appears to be a safe bound for when the fingerprint is ready
  // but is not consistently reliable. since the df is optional this is not a problem
  // of known scope
  const delay = 2500
  return new Promise((resolve) => {
    const existingFingerprint = storage.get(`deviceFingerprint`)
    if (existingFingerprint) {
      resolve(existingFingerprint)
      return
    }

    let adyenScript = document.createElement(`script`)
    adyenScript.onload = () => {
      window.dfDo(inputElementId)

      // this event and timeout should not be necessary, however because we are a SPA we need to trigger it manually
      document.dispatchEvent(new Event(`DOMContentLoaded`))

      setTimeout(() => {
        const elem = document.getElementById(inputElementId)
        if (elem) {
          // TODO: reject if null
          const fingerprint = elem.value
          storage.set(`deviceFingerprint`, fingerprint)
          resolve(fingerprint)
        }
      }, delay)
    }
    const adyenPath = `https://live.adyen.com/hpp/js/df.js?v=${new Date()}`
    adyenScript.setAttribute(`src`, adyenPath)

    document.body.appendChild(adyenScript)
  })
}

export function getLevelDown(level) {
  return `l${parseInt(level.split(``)[1]) + 1}`
}

export function getLevelUp(level) {
  return `l${parseInt(level.split(``)[1]) - 1}`
}

export function getLanguage() {
  // returns 2-letter language code
  // do some in-browser apps not support window.location.pathname properly? Hunch to fix this:
  // https://brandpath.atlassian.net/browse/LIP-626#icft=LIP-626

  const [route, countryFolderLangkey] = window.location.pathname.substr(1).split(`/`)
  const languagKey = getMetaOptions(`countryFolder`)
    ? countryFolderLangkey
    : route.split(`-`)[0]

  return languagKey
}

export function generateRouteLink(path, parents) {
  const cleanParents = parents.filter(Boolean)
  const base = cleanParents.length ? `/${cleanParents.join(`/`)}` : ``
  const trimmed = path.startsWith(`/`) ? path.slice(1) : path

  return `${base}/${trimmed}`
}

export function getIso3CountryCode(iso2Code, countries) {
  return countries.find(country => country.countryCode === iso2Code.toUpperCase())?.code || iso2Code
}

export function getCountryName(iso2Code, countries) {
  return countries.find(country => country.countryCode === iso2Code.toUpperCase())?.name || iso2Code
}
