// Checkout related
export const SHOW_TEST_PAYMENTS_PARAM = `showTestPayments`
export const MAX_LINE_ITEMS = 30
export const CAPTCHA_TRIGGER = `CAPTCHA_TRIGGER`
export const CAPTCHA_RETRY = `CAPTCHA_RETRY`

// Responsive device keys
export const DESKTOP_KEY = `desktop`
export const DESKTOP_SMALL_KEY = `desktopSmall`
export const TABLET_LARGE_KEY = `tabletLarge`
export const TABLET_MEDIUM_KEY = `tabletMedium`
export const TABLET_SMALL_KEY = `tabletSmall`
export const MOBILE_LARGE_KEY = `mobileLarge`

// Payment brandcodes
export const BRANDCODE_CREDITCARD = `creditcard`
export const BRANDCODE_PAYPAL = `paypal`
export const BRANDCODE_PAYPAL_EXPRESS = `paypal_ecs`
export const BRANDCODE_GOOGLEPAY = `paywithgoogle`
export const BRANDCODE_GOOGLEPAY_EXPRESS = `paywithgoogle_ecs`
export const BRANDCODE_APPLEPAY = `applepay`
export const BRANDCODE_APPLEPAY_EXPRESS = `applepay_ecs`
export const BRANDCODE_AFTERPAY = `afterpaytouch`
export const BRANDCODE_MOLPAY = `molpay_ebanking_fpx_MY`
export const BRANDCODE_KCP_CREDITCARD = `kcp_creditcard`
export const BRANDCODE_KCP_PAYCO = `kcp_payco`
export const BRANDCODE_KCP_BANKTRANSFER = `kcp_banktransfer`
export const BRANDCODE_WECHAT_QR = `wechatpayQR`
export const BRANDCODE_ALIPAY_HK = `alipay_hk`
export const BRANDCODE_ALIPAY = `alipay`
export const BRANDCODE_IDEAL = `ideal`

export const THIRD_PARTY_API_PAYMENT_METHODS = [BRANDCODE_GOOGLEPAY, BRANDCODE_APPLEPAY, BRANDCODE_IDEAL, BRANDCODE_MOLPAY]
export const THIRD_PARTY_API_EXPRESS_PAYMENT_METHODS = [BRANDCODE_GOOGLEPAY_EXPRESS, BRANDCODE_APPLEPAY_EXPRESS]

export const EMPTY_MANDATORY_FIELD_STUB = ` `
export const EMPTY_MANDATORY_POSTCODE_STUB = `00000`

// Payment order messages statuscodes
export const STATUSCODE_UNAVAILABLE_ITEMS = `UNAVAILABLE_ITEMS`
export const STATUSCODE_PREORDER_ITEMS = `PREORDER_ITEMS`

// Order details order messages statuscodes
export const STATUSCODE_ORDER_NOT_FOUND = `ORDER_NOT_FOUND`
export const STATUSCODE_SERVER_ERROR = `STATUSCODE_SERVER_ERROR`
export const STATUSCODE_CARD_MISSING = `CARD_MISSING`
export const STATUSCODE_EXPRESS_MISSING = `EXPRESS_MISSING`
export const STATUSCODE_PENDING = `PENDING`
export const STATUSCODE_REFUSED = `ADRESULT_REFUSED`
export const STATUSCODE_PREAUTH_FAILED = `PREAUTH_FAILED`
export const STATUSCODE_POPUSPS_DISALLOWED = `POPUPS_DISALLOWED`

// Account error statuscodes
export const STATUSCODE_ACCOUNT_NEEDS_RESET = `STATUSCODE_ACCOUNT_NEEDS_RESET`
export const STATUSCODE_ACCOUNT_INVALID_PASSWORD = `STATUSCODE_ACCOUNT_INVALID_PASSWORD`
export const STATUSCODE_ACCOUNT_INVALID_PW_COMPLEXITY = `STATUSCODE_ACCOUNT_INVALID_PW_COMPLEXITY`
export const STATUSCODE_ACCOUNT_INVALID_EMAIL = `STATUSCODE_ACCOUNT_INVALID_EMAIL`
export const STATUSCODE_ACCOUNT_USER_EXISTS = `STATUSCODE_ACCOUNT_USER_EXISTS`
export const STATUSCODE_ACCOUNT_CODE_EXPIRED = `STATUSCODE_ACCOUNT_CODE_EXPIRED`
export const STATUSCODE_ACCOUNT_CODE_INVALID = `STATUSCODE_ACCOUNT_CODE_INVALID`
export const STATUSCODE_ACCOUNT_INVALID_CREDS = `STATUSCODE_ACCOUNT_INVALID_CREDS`
export const STATUSCODE_ACCOUNT_CODE_NEW_CODE = `STATUSCODE_ACCOUNT_CODE_NEW_CODE`
export const STATUSCODE_ACCOUNT_UNABLE_TO_ACTION = `STATUSCODE_ACCOUNT_UNABLE_TO_ACTION`
export const STATUSCODE_ACCOUNT_SUCCESS_NEW_PASSWORD = `STATUSCODE_ACCOUNT_SUCCESS_NEW_PASSWORD`
export const STATUSCODE_ACCOUNT_SUCCESS_CREATED = `STATUSCODE_ACCOUNT_SUCCESS_CREATED`
export const STATUSCODE_ACCOUNT_INCORRECT_PASSWORD = `STATUSCODE_ACCOUNT_INCORRECT_PASSWORD`
export const STATUSCODE_ACCOUNT_ATTEMPTS_EXCEEDED = `STATUSCODE_ACCOUNT_ATTEMPTS_EXCEEDED`
export const STATUSCODE_ACCOUNT_CODE_SENDS_EXCEEDED = `STATUSCODE_ACCOUNT_CODE_SENDS_EXCEEDED`
export const STATUSCODE_ACCOUNT_WEAK_PASSWORD = `STATUSCODE_ACCOUNT_WEAK_PASSWORD`
export const STATUSCODE_ACCOUNT_SECURITY_ISSUES = `STATUSCODE_ACCOUNT_SECURITY_ISSUES`
export const STATUSCODE_ACCOUNT_ERROR_CAPTCHA = `STATUSCODE_ACCOUNT_ERROR_CAPTCHA`
export const STATUSCODE_ACCOUNT_MARKETING_INTERESTS = `STATUSCODE_ACCOUNT_MARKETING_INTERESTS`

export const STATUSCODE_PAYMENT_METHOD_UNABLE_TO_DELETE = `STATUSCODE_PAYMENT_METHOD_UNABLE_TO_DELETE`
export const STATUSCODE_PAYMENT_METHOD_UNABLE_TO_LIST = `STATUSCODE_PAYMENT_METHOD_UNABLE_TO_LIST`
export const STATUSCODE_PAYMENT_METHOD_UNABLE_TO_UPDATE = `STATUSCODE_PAYMENT_METHOD_UNABLE_TO_UPDATE`
export const STATUSCODE_PAYMENT_METHOD_UNABLE_TO_ADD = `STATUSCODE_PAYMENT_METHOD_UNABLE_TO_ADD`
export const STATUSCODE_PAYMENT_METHOD_TOO_MANY_ATTEMPTS = `STATUSCODE_PAYMENT_METHOD_TOO_MANY_ATTEMPTS`
export const STATUSCODE_PAYMENT_METHOD_UNAUTHORIZED = `STATUSCODE_PAYMENT_METHOD_UNAUTHORIZED`

export const STATUSCODE_ADDRESS_UNABLE_TO_DELETE = `STATUSCODE_ADDRESS_UNABLE_TO_DELETE`
export const STATUSCODE_ADDRESS_UNABLE_TO_LIST = `STATUSCODE_ADDRESS_UNABLE_TO_LIST`
export const STATUSCODE_ADDRESS_UNAUTHORIZED = `STATUSCODE_ADDRESS_UNAUTHORIZED`
export const STATUSCODE_ADDRESS_UNABLE_TO_UPDATE = `STATUSCODE_ADDRESS_UNABLE_TO_UPDATE`
export const STATUSCODE_ADDRESS_UNABLE_TO_ADD = `STATUSCODE_ADDRESS_UNABLE_TO_ADD`

// PDP Errors
export const STATUSCODE_STOCK_QUANTITY_EXCEEDED = `STATUSCODE_STOCK_QUANTITY_EXCEEDED`
export const STATUSCODE_MAX_QUANTITY_EXCEEDED = `STATUSCODE_MAX_QUANTITY_EXCEEDED`
export const STATUSCODE_SERVER_MAX_QUANTITY_EXCEEDED = `MAX_QTY_PER_ITEM_EXCEEDED`
export const STATUSCODE_ADD_CART_FAILURE = `STATUSCODE_ADD_CART_FAILURE`
export const MAX_CART_QTY_EXCEEDED = `MAX_CART_QTY_EXCEEDED`
export const STATUSCODE_SECONDARY_STOCK_QUANTITY_EXCEEDED = `STATUSCODE_SECONDARY_STOCK_QUANTITY_EXCEEDED`
export const STATUSCODE_SECONDARY_MAX_QUANTITY_EXCEEDED = `STATUSCODE_SECONDARY_MAX_QUANTITY_EXCEEDED`

// PLP
export const HITS_PER_PAGE = 120

// PDP
export const MAX_PRODUCT_VARIANTS = 1000

// Product
export const AVAILABILITY_BACKORDER = `BACKORDER`
export const AVAILABILITY_PREORDER = `PREORDER`
export const AVAILABILITY_AVAILABLE = `AVAILABLE`
export const AVAILABILITY_LOWSTOCK = `LOWSTOCK`
export const AVAILABILITY_OUTOFSTOCK = `OUTOFSTOCK`
export const AVAILABILITY_DISCONTINUED = `DISCONTINUED`
export const AVAILABILITY_GIFTONLY = `GIFTONLY`
export const AVAILABILITY_NOTAVAILABLE = `NOTAVAILABLE`
export const AVAILABILITY_FLAGS = {
  '-2': AVAILABILITY_BACKORDER,
  '-1': AVAILABILITY_PREORDER,
  1: AVAILABILITY_AVAILABLE,
  2: AVAILABILITY_LOWSTOCK,
  3: AVAILABILITY_OUTOFSTOCK,
  4: AVAILABILITY_DISCONTINUED,
}
export const ORDERABLE_STATES = [AVAILABILITY_AVAILABLE, AVAILABILITY_LOWSTOCK, AVAILABILITY_PREORDER, AVAILABILITY_BACKORDER]
export const SALE_STATES = [`NOSALE`, `ONSALE`]

// Promotion areas
export const PROMOTIONAREA_PRODUCTCARD_ADDITIONAL = `productCard/additional`
export const PROMOTIONAREA_PRODUCTCARD_BADGE = `productCard/badge`
export const PROMOTIONAREA_PRODUCTCARD_BANNER = `productCard/banner`

export const PROMOTIONAREA_PDP_HIGHLIGHT = `pdp/highlight`
export const PROMOTIONAREA_PDP_EXPANDABLE = `pdp/expandable`
export const PROMOTIONAREA_PDP_ADDITIONAL = `pdp/additional`

export const PROMOTIONAREA_CHECKOUT_SHIPPINGPROMO = `checkout/shippingPromo`

// display translation strings
export const LABELS_LANGUAGE = `webLabels`

// storage
export const CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY = `productCustomisationInitial`
export const SHIPPING_ADDRESS_STORAGE_KEY = `shippingAddress`
export const BILLING_ADDRESS_STORAGE_KEY = `billingAddress`
export const ADDRESS_SOURCE_LOCALSTORAGE_KEY = `localStorage`
export const ADDRESS_SOURCE_ADDRESSBOOKS_KEY = `addressBooks`
export const ADDRESS_SOURCE_WALLETS_KEY = `wallets`
export const ADDRESS_SOURCE_FORM_KEY = `form`
export const ADDRESS_SOURCE_THIRD_PARTY_KEY = `thirdParty`
export const ADDRESS_SOURCE_MATCH_SHIPPING = `matchShipping`
export const ADDRESS_SOURCE_CART_API = `cartApi`
export const RAKUTEN_STORAGE_KEY = `rakutenData`

// Adyen
export const ADYEN_PRODUCTION_KEY = `live_BPISPEHR5ZCELIBLU5KZ7SP5TYK4CPDQ`
export const ADYEN_TEST_KEY = `test_NIYUZGGB55EVPPQO2OM3WPOQKYN7BJTJ`

// maps our cardType key with adyen's
export const ADYEN_CARD_TYPE_MAP = {
  mastercard: `mc`,
  'american-express': `amex`,
  unionpay: `cup`,
}

// Form ids
export const CHECKOUT_STEP_SHIPPING_ID = `checkout-shipping-form`
export const CHECKOUT_STEP_PAYMENT_ID = `checkout-payment-form`
export const CHECKOUT_STEP_REVIEW_ID = `checkout-review-form`

// Tax Calculation States
export const TAX_NOT_CALCULATED = `NOT_CALCULATED`
export const TAX_CALCULATED = `CALCULATED`
export const TAX_PENDING_CALCULATION = `PENDING_CALCULATION`
export const TAX_REJECTION_ERROR = `TAX_REJECTION`

// Events
export const SENTRY_READY = `SENTRY_READY`

// Signup Username Form Type
export const SIGNUP_EMAIL_AND_PHONE = `SIGNUP_EMAIL_AND_PHONE`
export const SIGNUP_EMAIL_ONLY = `SIGNUP_EMAIL_ONLY`
export const SIGNUP_PHONE_ONLY = `SIGNUP_PHONE_ONLY`

// Form errors
export const UNABLE_TO_SHIP_ERROR = `UNABLE_TO_SHIP_ERROR`

// Product Trays
export const OBSERVED_PRODUCT_KEY = `observedProducts`
export const TOTAL_OBSERVED_PRODUCTS = 20

// Vendor Interests Groups
export const INTEREST_MAILCHIMP = `mailchimp`
export const INTEREST_KLAVIYO = `klaviyo`

// Query parameters for hiding components
export const QUERY_PARAM_HIDE_ALL_POPUP = `hide_all_popup`
export const QUERY_PARAM_HIDE_MODAL = `hide_modal`
export const QUERY_PARAM_HIDE_COOKIES = `hide_cookies`
export const QUERY_PARAM_HIDE_KLAVIYO = `hide_klaviyo`
