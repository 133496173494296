import { fetchAtmos } from 'services/api'

export function getProductCustomisation({
  productId,
}) {
  return fetchAtmos({
    path: `/v1/products/${productId}/customise`,
  })
}

export function postProductCustomisation({
  productId,
  selectedOptions,
}) {
  return fetchAtmos({
    path: `/v1/products/${productId}/customise`,
    options: {
      method: `POST`,
      body: JSON.stringify({
        selectedOptions,
      }),
    },
  })
}

