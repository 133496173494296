import React from 'react'
import get from 'lodash/get'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import { useGlobalContent } from 'hooks/useGlobalContent'
import { useFilters } from 'hooks/useFilters'
import { useUrlManager } from 'hooks/useUrlManager'
import { getConfigOptions } from 'global-content/config'
import { generateRouteLink } from 'utils'
import { SideNavLink } from 'components/SideNavLink'
import { CategoryNav } from 'components/CategoryNav'
import * as algolia from 'services/algolia'

import styles from './SideNav.css'

export const SideNav = ({
  fontSize,
  onClick,
}) => {
  const { batchApply } = useFilters()
  const navMenu = useGlobalContent(`navMenu`)
  const mainNavTree = useGlobalContent(`navTree`)
  const {
    pathname,
    search,
  } = useLocation()
  const urlManager = useUrlManager()
  const levels = pathname.split(`/`).slice(1)

  const navTree = get(navMenu, `sideNavigation.replacement`) || mainNavTree
  const [l1] = levels

  if (get(navTree, `l1[${l1}].l2`)) {
    return (
      <div
        className={styles.sideNav}
        data-testid="side-nav"
      >
        {renderContent()}
      </div>
    )
  }

  return (
    <CategoryNav
      fontSize={fontSize}
      levelsToShow={1}
      onClick={onClick}
    />
  )

  function renderContent() {
    let build = []
    walkTree(navTree.l1[l1].l2, [l1])

    return build

    function walkTree(branch, parents) {
      const currentLevel = parents.length
      const childLevel = `l${currentLevel + 2}`
      const key = levels[currentLevel]

      if (branch[key] && branch[key][childLevel]) {
        const childBranch = branch[key][childLevel]

        walkTree(childBranch, [...parents, key])
      } else {
        build = buildLinks(branch, parents)
      }
    }
  }

  function buildLinks(routes, parents) {
    return Object.keys(routes)
      .filter(slug => !routes[slug].hide)
      .map(slug => {
        const route = routes[slug]
        const path = route.path || generateRouteLink(slug, parents)
        const validateLinkFn = async() => {
          const notEmpty = await algolia.notEmpty(route.filters)
          return notEmpty
        }

        return (
          <SideNavLink
            active={slug === levels[levels.length - 1]}
            aria-label={route.accessibilityDesc || route.label}
            button
            fontSize={fontSize}
            key={slug}
            name={route.accessibilityDesc || route.label}
            onClick={() => {
              onClick && onClick()
              handleClick(path)
            }}
            validateLinkFn={validateLinkFn}
            value={route.label}
          />
        )
      })
  }

  function handleClick(path) {
    const sortOptions = getConfigOptions(`sortOptions`)
    const value = path.split(`?`)
    let appendedParams = {}

    if (value[1]) {
      appendedParams = queryString.parse(decodeURIComponent(value[1]), { arrayFormat: `comma` })
    }

    const sortOption = sortOptions.find(option => option.value === queryString.parse(search).sortBy)
    const remove = [`page`, `featuredProductIds`]

    // some categories don't support a currently selected sortBy
    // i.e. /women/hats?sortBy=featured-boots doesn't make sense
    // this is a check for whether the sortBy should persist between category clicks

    if (
      sortOption &&
      sortOption.onlyAppear &&
      !sortOption.onlyAppear.includes(value[0])
    ) {
      delete appendedParams.sortBy
      remove.push(`sortBy`)
    }

    if (value[0] === pathname) {
      const {
        sortBy,
        query,
        page,
        ...filters
      } = appendedParams

      batchApply({
        filters,
        sortBy,
        query,
        page,
      })
    } else {
      urlManager({
        pathname: {
          value: value[0],
        },
        params: {
          append: appendedParams,
          remove,
        },
      })
    }
  }
}
