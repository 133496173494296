import { getMetaOptions } from 'global-content/config'
import { utilizeUserAuth } from 'global-content/utils'
import store from 'state/reducers'
import { heapAnalytics } from 'services/heap-analytics'
import { Events } from 'design-system/services/events'

export async function accountSignUp({
  userName,
  password,
  marketingOptIn,
  attributes,
  captcha,
}) {
  const Auth = await utilizeUserAuth()

  return Auth.signUp({
    username: userName,
    password,
    userAttributes: attributes,
    clientMetadata: getCognitoClientMetaData({ marketingOptIn, ...captcha }),
  }).then(promiseResponse => {
    heapAnalytics.setIdentity({ email: userName })
    Events.emitIdentifyUserSession({ userEmail: userName })
    return promiseResponse
  })
}

export async function refreshSession() {
  const Auth = await utilizeUserAuth()

  return Auth.refreshSession()
}

export async function accountResendSignUpCode({ userName }) {
  const Auth = await utilizeUserAuth()

  return Auth.resendSignUp(userName, getCognitoClientMetaData())
}

export async function accountForgotPassword({
  captcha,
  userName,
}) {
  const Auth = await utilizeUserAuth()

  return Auth.forgotPassword(userName, getCognitoClientMetaData(captcha))
}

export async function accountSetNewPassword({
  userName,
  code,
  newPassword,
}) {
  const Auth = await utilizeUserAuth()

  return Auth.forgotPasswordSubmit(userName, code, newPassword, getCognitoClientMetaData())
}

export async function accountChangePassword({
  currentPassword,
  newPassword,
}) {
  const Auth = await utilizeUserAuth()

  return Auth.changePassword(currentPassword, newPassword)
}

export async function accountConfirmSignUp({
  userName,
  code,
}) {
  const Auth = await utilizeUserAuth()

  return Auth.confirmSignUp(
    userName,
    code,
    getCognitoClientMetaData()
  )
}

let signInAttempts = 0

export async function accountSignIn({
  captcha,
  userName,
  password,
}) {
  const Auth = await utilizeUserAuth()
  console.log(`api/account - Attempt sign in`)

  return (
    Auth.signIn(userName, password, getCognitoClientMetaData(captcha))
      .then(response => {
        console.log(`api/account - Signed in`)
        signInAttempts = 0
        heapAnalytics.setIdentity({ email: userName })
        Events.emitIdentifyUserSession(getUserSessionProps(response?.idToken?.payload))
        return response
      })
      .catch(err => {
        console.error(`api/account - Failed to sign in`)

        // clear storage and try again before erroring out
        if (err.message === `Device does not exist.` && signInAttempts === 0) {
          console.log(`api/account - Clear localStorage and try again`)
          signInAttempts++
          clearCognitoStorage()
          return accountSignIn({ userName, password })
        }
        console.log(`api/account - failed`)
        throw err
      })
  )
}

export async function accountSignOut() {
  const Auth = await utilizeUserAuth()
  heapAnalytics.resetIdentity()
  Events.emitResetUserSession()
  return Auth.signOut()
}

export async function accountGetCurrentUser() {
  const Auth = await utilizeUserAuth()

  return Auth.currentAuthenticatedUser()
    .then(response => {
      Events.emitIdentifyUserSession(getUserSessionProps(response))
      if (response && response.email) {
        heapAnalytics.setIdentity({ email: response.email })
      }
      return response
    })
    .catch(err => {
      clearCognitoStorage()
      throw err
    })
}

// If a user deletes their account
// amplify keeps a reference to it in localStorage
// if they recreate the account the references mess up the log-in

function clearCognitoStorage() {
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith(`Cognito`)) {
      localStorage.removeItem(key)
    }
  })
}

function getCognitoClientMetaData(additionalData = {}) {
  const language = store.getState().language.active
  const country = getMetaOptions(`country.code`)
  const countryFolder = getMetaOptions(`countryFolder`)
  return {
    client: getMetaOptions(`clientTag`),
    country,
    language,
    domain: `${window.location.protocol}//${window.location.host}`,
    subfolder: countryFolder ? countryFolder.slice(1) : undefined,
    locale: `${language}-${country}`,
    ...additionalData,
  }
}

function getUserSessionProps(response) {
  if (response) {
    const userLanguage = store.getState()?.language?.active
    const userLocale = store.getState()?.language?.locale

    return {
      userEmail: response?.email,
      userGivenName: response?.given_name,
      userFamilyName: response?.family_name,
      userId: response?.sub,
      shoppingSessionId: window?.$shoppingSessionId?.value,
      userLocale,
      userLanguage,
    }
  }
  return {}
}
