export const webLabels = {
  cartStep: `WEB.CART.NAME.*`,
  country: `COUNTRY.*`,
  siteName: `SITE.NAME`,
  maintenanceHeading: `WEB.MAINTENANCE.HEADING`,
  maintenanceDesc: `WEB.MAINTENANCE.MESSAGE`,
}

export const mockFiltersMap = {
  brand: {
    filterType: `facet`,
    nameInAlgolia: `brand.tag`,
    webLabel: `WEB.LISTING.FILTER.BRAND.TITLE`,
  },
  categories: {
    filterType: `facet`,
    nameInAlgolia: `categories.tag`,
  },
  categoryPageIds: {
    filterType: `unfaceted`,
    nameInAlgolia: `categoryPageIds`,
  },
  collections: {
    filterType: `facet`,
    nameInAlgolia: `collections.tag`,
  },
  color: {
    categoryLabel: `colorGroup`,
    filterType: `facet`,
    nameInAlgolia: `color.tag`,
    webLabel: `WEB.LISTING.FILTER.COLOR.TITLE`,
  },
  priceRange: {
    currency: true,
    filterType: `numeric`,
    nameInAlgolia: `price.JPY-JP.sale`,
    webLabel: `WEB.LISTING.FILTER.PRICE.TITLE`,
  },
  size: {
    filterType: `facet`,
    nameInAlgolia: `size.tag`,
    webLabel: `WEB.LISTING.FILTER.SIZE.TITLE`,
  },
  slug: {
    filterType: `unfaceted`,
    nameInAlgolia: `slug`,
  },
  productId: {
    filterType: `unfaceted`,
    nameInAlgolia: `productId`,
  },
  prioritizedProductIds: {
    filterType: `facet`,
    nameInAlgolia: `productId`,
  },
  onSale: {
    filterType: `boolean`,
    nameInAlgolia: `price.JPY-JP.onSale`,
  },
  includeOutOfStock: {
    filterType: `ignore`,
  },
}