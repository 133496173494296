import {
  FAVORITE_ADD,
  FAVORITE_REMOVE,
} from 'state/actions'
import pick from 'lodash/pick'
import { unversionedStorage } from 'utils/storage'

const validate = (favorites = []) => {
  let formattedFaves = favorites
  // Convert old favorite models to new format that only contains an id property to save space
  if (favorites.some(favorite => favorite.availability)) {
    formattedFaves = favorites.map(favorite => pick(favorite, [`id`])).filter(favorite => favorite && favorite.id)
    saveFavorites(formattedFaves)
  }

  return formattedFaves
}

const initialState = {
  all: validate(unversionedStorage.get(`favorites`) || []),
}

function saveFavorites(favorites) {
  unversionedStorage.set(`favorites`, favorites)
}

const favorites = (state = initialState, action) => {
  switch (action.type) {
  case FAVORITE_ADD: {
    const toAdd = {
      id: action.payload.id,
    }
    const combinedFavorites = state.all.concat(toAdd)
    saveFavorites(combinedFavorites)
    return {
      ...state,
      all: combinedFavorites,
    }
  }
  case FAVORITE_REMOVE: {
    const toRemove = action.payload
    const revisedFavorites = state.all.filter(f => f.id !== toRemove.id)
    saveFavorites(revisedFavorites)
    return {
      ...state,
      all: revisedFavorites,
    }
  }
  default:
    return state
  }
}

export default favorites
