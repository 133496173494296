import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { updateGoogleAnalytics } from 'services/analytics'
import { useRouteChange } from 'hooks/useRouteChange'
import { changeLanguage } from 'state/actions/language'
import { updateLangDir } from 'state/actions/site'
import { getNewLangUrl } from 'state/reducers/language'
import { contentDependencies } from 'global-content/contentDependencies'
import { getLocale } from 'utils'
import { Events } from 'design-system/services/events'
import { getMetaOptions } from 'global-content/config'

export function useChangeLanguage() {
  const activeLanguage = useSelector(state => state.language.active)
  const languagesViewed = useSelector(state => state.site.languagesViewed)
  const adminMode = useSelector(state => state.site.adminMode)
  const dispatch = useDispatch()
  const {
    pathname,
    search,
  } = useLocation()
  const updateLocation = useRouteChange()

  return function switchLanguage(language) {
    if (!languagesViewed.includes(language)) {
      contentDependencies(language).then(() => {
        handleSwitch()
      })
    } else {
      handleSwitch()
    }

    function handleSwitch() {
      // Send analytics events
      !adminMode && updateGoogleAnalytics(`switchLanguage`, {
        text: language,
      })
      Events.emitLanguageSwitched({
        userLocale: getLocale(language),
      })

      // Reload the page (does not require any state updates)
      if (shouldForceReload()) {
        // Note: Wait 50ms so any analytics event on language switch can be sent
        window.setTimeout(() => {
          const newUrl = getNewLangUrl(activeLanguage, language)
          window.location.replace(newUrl)
        }, 50)
        return
      }

      // Update state
      dispatch(changeLanguage(language))
      dispatch(updateLangDir(language))
      updateLocation({
        pathname,
        search,
      })
    }
  }

  // Note: Reload the page when specific vendors are enabled on the SPA website.
  // The reason for the reload is because vendor's components cannot detect
  // React client-side language changes so the vendor component's language
  // could be out-of-sync with our site.
  function shouldForceReload() {
    const isKlaviyoEnabled = getMetaOptions(`integrations.klaviyo.publicApiKey`)
    return isKlaviyoEnabled
  }
}
