import { submitEmail } from 'state/actions/emailSignup'

// Callback function to subscribe a user to our internal services
// after a klaviyo subscribe popup form has been submitted
function internalSubscribeApiCallback() {
  window.addEventListener(`klaviyoForms`, function(event) {
    if (event.detail.type === `submit`) {
      const email = event?.detail?.metaData?.$email
      submitEmail({ email })
    }
  })
}

export function klaviyoInitCallback() {
  internalSubscribeApiCallback()
}
