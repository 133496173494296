import { removeNewLinesAndTabs } from '../../utils/removeWhiteSpace'

const QUERY_PARAM_INTERAL = `internal`
const DISABLE_TRACKING_LOCALSTORAGE_KEY = `disableTracking`

// Note: Disable tracking because traffic is from internal usage like LTE automated tests
export function tracking() {
  let urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has(QUERY_PARAM_INTERAL)) {
    window.localStorage.setItem(DISABLE_TRACKING_LOCALSTORAGE_KEY, true)
  }
}

export function isTracking() {
  return !window.localStorage.getItem(DISABLE_TRACKING_LOCALSTORAGE_KEY)
}

// Note: Since the analytics script is loaded before the React App, we need
// to determine if we should be tracking based on the URL/LocalStorage before
// we init the analytics package. Therefore, we need to set this up in webpack.
export const WEBPACK_IS_TRACKING_IIFE = removeNewLinesAndTabs(`
(function() {
  try {
    if (Boolean(URLSearchParams)) {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("${QUERY_PARAM_INTERAL}")) {
        return false;
      }
    }

    if (
      window.localStorage &&
      window.localStorage.getItem("${DISABLE_TRACKING_LOCALSTORAGE_KEY}") === "true"
    ) {
      return false;
    }

    if (window.location.host && window.location.host.includes("preview.localised")) {
      return false;
    }
  } catch(e) {}

  return true;
})()
`)
