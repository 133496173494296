import {
  DETAILS_PRELOAD,
  DETAILS_ADD_MESSAGE,
  DETAILS_REMOVE_MESSAGE,
  GET_PRODUCT,
  GET_PRODUCT_CUSTOMISATION,
  POST_PRODUCT_CUSTOMIZATION,
  PRE_SELECT_PRODUCT_CUSTOMISATION,
  NO_PRODUCT,
  UPDATE_SELECTED_OPTION,
  SET_PENDING_QUANTITY,
  UPDATE_MISSING_LEVELS,
  VALIDATE_PRODUCT_CUSTOMISATION,
} from "state/actions"
import { CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY } from "utils/constants"
import { normalizeProductVariations, getGroupProductFallback } from 'state/utils/details'
import { unversionedStorage } from 'utils/storage'
import get from 'lodash/get'
import * as api from 'services/api'

export const detailsPreload = ({
  indexName,
  product,
  selectedSlugs,
  filters,
  queryID,
}) => ({
  type: DETAILS_PRELOAD,
  payload: {
    indexName,
    product,
    selectedSlugs,
    filters,
    queryID,
  },
})

export const detailsAddMessage = payload => ({
  type: DETAILS_ADD_MESSAGE,
  payload,
})

export const detailsRemoveMessage = payload => ({
  type: DETAILS_REMOVE_MESSAGE,
  payload,
})

export const initProductCustomisation = ({
  productId,
}) => async(dispatch, getState) => {
  try {
    await dispatch({
      type: GET_PRODUCT_CUSTOMISATION,
      payload: api.getProductCustomisation({ productId }),
    })
  } catch (err) {
    console.warn(err)
  }
  const customisationOptions = get(getState(), `details.customisation.options`, {})
  const { key } = Object.values(customisationOptions).find(({ type }) => type === `text`) || {}
  let selectedOptions = {}
  if (key) {
    selectedOptions = {
      [key]: unversionedStorage.get(CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY) || ``,
    }
    dispatch({
      type: PRE_SELECT_PRODUCT_CUSTOMISATION,
      payload: {
        id: key,
        value: selectedOptions[key],
      },
    })
  }

  await dispatch(postProductCustomisation({
    productId,
    selectedOptions,
  }))
}

export const getProduct = ({
  callback,
  productId,
  language,
  getPromotions,
  search,
}) => async(dispatch, getState) => {
  try {
    await dispatch({
      type: GET_PRODUCT,
      payload: (
        api.getProduct({ productId, language, getPromotions, search })
          .catch(() => getGroupProductFallback({ productId, language, getPromotions, search }))
          .then((product) => {
            if (product.customisation) {
              dispatch(initProductCustomisation({ productId }))
            }
            return product
          })
          .then(async(product) => normalizeProductVariations({ product, language }))
          .then(product => ({
            product,
            getPromotions,
            search,
          }))
      ),
    })
    callback(getState)
  // Error is already handled by redux middleware.  No need to throw.
  } catch (err) {
    console.warn(err)
  }
}

export const postProductCustomisation = ({
  selectedOptions,
}) => async(dispatch, getState) => {
  const options = get(getState(), `details.customisation.options`, {})
  const defaultOptions = Object.values(options).reduce((accumulatedDefaultValues, {
    key,
    defaultValue,
  }) => ({
    ...accumulatedDefaultValues,
    [key]: defaultValue,
  }), {})
  const productId = get(getState(), `details.product.id`)
  const requestOptions = {}
  for (const key in defaultOptions) {
    requestOptions[key] = !selectedOptions[key] ? defaultOptions[key] : selectedOptions[key]
  }
  try {
    await dispatch({
      type: POST_PRODUCT_CUSTOMIZATION,
      payload: api.postProductCustomisation({
        productId,
        selectedOptions: requestOptions,
      }),
    })
  } catch (err) {
    console.warn(err)
  }
}

export const selectProductCustomisation = (selection, isInput) => async(dispatch, getState) => {
  dispatch({
    type: PRE_SELECT_PRODUCT_CUSTOMISATION,
    payload: selection,
  })
  const selectedOptions = get(getState(), `details.customisation.selectedOptions`)
  try {
    await dispatch(postProductCustomisation({
      selectedOptions,
    }))
    if (isInput) {
      unversionedStorage.set(CUSTOMISATION_INITIAL_LOCALSTORAGE_KEY, selection.value)
    }
  } catch (err) {
    console.warn(err)
  }
}

export const validateProductCustomisation = payload => ({
  type: VALIDATE_PRODUCT_CUSTOMISATION,
  payload: payload,
})

export const noProduct = () => ({
  type: NO_PRODUCT,
})

export const setPendingQuantity = payload => ({
  type: SET_PENDING_QUANTITY,
  payload,
})

export const updateMissingLevels = payload => ({
  type: UPDATE_MISSING_LEVELS,
  payload,
})

export const updateSelectedDetailOption = ({
  options,
  urlManager,
  getPromotions,
  isValid,
}) => async(dispatch) => {
  await dispatch({
    type: UPDATE_SELECTED_OPTION,
    payload: {
      options,
      getPromotions,
      isValid,
    },
  })

  urlManager({
    method: `replace`,
    params: {
      append: options,
      remove: [`sku`],
    },
  })
}
