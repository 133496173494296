import React from 'react'

import { I18n } from 'utils/i18n'
import { unversionedStorage } from 'utils/storage'
import { Svg } from 'components/Svg'
import { Link } from 'components/Link'
import { getConfigOptions } from 'global-content/config'
import { getQueryParamHideCookieBanner } from 'utils/getQueryParamHideComponents'

import { COOKIE_MESSAGE_ID, webLabels } from './fixtures'
import styles from './CookieMessage.css'

export const CookieMessage = () => {
  const messageKey = `cookieMessage`
  const isDisabled = getConfigOptions(`cookieMessageDisabled`)
  const shouldHideCookies = getQueryParamHideCookieBanner()
  const [hide, setHide] = React.useState(Boolean(unversionedStorage.get(messageKey)) || shouldHideCookies)

  if (!hide && !isDisabled) {
    const closeLabel = I18n.t(webLabels.close)
    return (
      <div
        className={styles.cookieMessage}
        id={COOKIE_MESSAGE_ID}
      >
        <div className={styles.wrapper}>
          <div>
            {I18n.t(webLabels.desc)}
            <Link
              aria-label={I18n.t(webLabels.cta)}
              className={styles.link}
              name={I18n.t(webLabels.cta)}
              to="/about-us/privacy-policy"
            >
              {I18n.t(webLabels.cta)}
            </Link>
          </div>
        </div>
        <button
          aria-label={closeLabel}
          className={styles.closeIcon}
          name={closeLabel}
          onClick={hideMessage}
        >
          <Svg
            icon="close"
            size="2.2rem"
          />
        </button>
      </div>
    )
  }

  return null

  function hideMessage() {
    unversionedStorage.set(messageKey, `hide`)
    setHide(true)
  }
}
