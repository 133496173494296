"use strict";
exports.__esModule = true;
exports.getSizeGuideIndex = void 0;
var fixtures_1 = require("./fixtures");
function getSizeGuideIndex(_a) {
    var categories = _a.categories, product = _a.product;
    if (!product)
        return 0;
    var productCriteria = getProductCriteria(product);
    var index = matchProductToCriteria(productCriteria, categories);
    return index;
}
exports.getSizeGuideIndex = getSizeGuideIndex;
function getProductCriteria(product) {
    return {
        categoryPageIds: (product.categoryPageIds || []),
        brand: product.brand.name,
        collections: product.collections.map(function (collection) { return collection.tag; }),
        categories: product.categories,
        keywords: product.slug.toLowerCase().split("-")
    };
}
// Helper function to count matching elements in arrays
function countMatches(array1, array2) {
    array2 = array2.map(function (item) { return item.toLowerCase(); });
    return array1.filter(function (item) { return array2.includes(item.toLowerCase()); }).length;
}
function matchProductToCriteria(product, criteria) {
    var bestMatchIndex = 0;
    var bestMatchScore = -1;
    for (var i = 0; i < criteria.length; i++) {
        var score = 0;
        var criterion = criteria[i];
        // Check if there's a match in categoryPageIds
        if (criterion.categoryPageIds && product.categoryPageIds) {
            var categoryPageIdMatches = countMatches(product.categoryPageIds, criterion.categoryPageIds);
            if (categoryPageIdMatches > 0) {
                score += categoryPageIdMatches * fixtures_1.CATAGORY_PAGE_ID_RANK; // High weight to ensure precedence
            }
        }
        // Check if there's a brand match
        if (product.brand && criterion.brands) {
            var brandMatches = countMatches([product.brand], criterion.brands);
            if (criterion.brands.length !== 0 && brandMatches === 0) {
                continue;
            }
            score += brandMatches * fixtures_1.BRAND_RANK; // High but less than categoryPageIds
        }
        // Count collections matches
        if (product.collections && criterion.collection) {
            var collectionMatches = countMatches(product.collections, criterion.collection);
            score += collectionMatches * fixtures_1.CATEGORY_COLLECTION_RANK;
        }
        // Count categories matches
        if (product.categories && criterion.categories) {
            var categoryMatches = countMatches(product.categories, criterion.categories);
            score += categoryMatches * fixtures_1.CATEGORY_COLLECTION_RANK;
        }
        // Count keywords matches if other criteria is matched
        if (score > 0 && product.keywords && criterion.slugKeywords) {
            var keywordMatches = countMatches(product.keywords, criterion.slugKeywords.map(function (keyword) { return keyword.toLowerCase(); }));
            score += keywordMatches * fixtures_1.KEYWORD_RANK;
        }
        if (score > bestMatchScore) {
            bestMatchScore = score;
            bestMatchIndex = i;
        }
    }
    return bestMatchIndex;
}
