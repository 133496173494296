import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'utils/i18n'
import { getConfigOptions } from 'global-content/config'
import { ProductSizeGuidesVBTableTab } from './ProductSizeGuidesVBTableTab'
import { ProductSizeGuidesVBMeasureTab } from './ProductSizeGuidesVBMeasureTab'
import { getSizeGuideIndex } from './utilities'
import styles from './ProductSizeGuidesVB.css'

const TABLE_TAB = 0
const HOW_TO_MEASURE_TAB = 1

export const ProductSizeGuidesVBBody = (props) => {
  const { sizeGuideProperties } = props
  const [selectedTab, setSelectedTab] = useState(TABLE_TAB)

  // Destructured properties
  const {
    categories,
    howToMeasureData,
  } = sizeGuideProperties.data

  const [selectedIndexCategorySizeGuide, setSelectedIndexCategorySizeGuide] = useState(0)

  const hasRootHowToMeasureData = Boolean(howToMeasureData)
  const hasCategoryHowToMeasureData = categories.some((categoryItem) => Boolean(categoryItem.categoryHowToMeasureData))
  const shouldRenderHowToMeasureTab = hasRootHowToMeasureData || hasCategoryHowToMeasureData

  // Select the category size guide based on product's measurementChart
  const product = useSelector(state => state.details.product)

  useEffect(() => {
    const division = product?.collections?.find(collection => collection?.tag?.includes(`division`))?.tag

    const isAgregationEnabled = getConfigOptions(`sizeGuide.enableAgregation`)

    if (isAgregationEnabled) {
      setSelectedIndexCategorySizeGuide(
        getSizeGuideIndex({
          categories,
          product,
        })
      )
    } else if (division) {
      const name = product.slug.split(`-`).join(` `)
      const categoryKey = getCategoryKeyByDivisionAndName(name, division)
      // set index
      if (categoryKey >= 0) {
        setSelectedIndexCategorySizeGuide(categoryKey)
      }
    } else {
      const measurementChart = product?.measurementChart || null
      const measurementChartMatchingSizeGuideIndex = findIndexOfSizeGuideCategory(measurementChart)
      if (
        measurementChart &&
        measurementChartMatchingSizeGuideIndex >= 0 &&
        measurementChartMatchingSizeGuideIndex !== selectedIndexCategorySizeGuide
      ) {
        selectCategoryChange(measurementChart)
      }
    }
  }, [product])


  function doesWordExistInString(wordList, searchString) {
    if (wordList) {
      return wordList.some(word => searchString?.toLowerCase()?.includes(word?.toLowerCase()))
    }
    return false
  }

  function getCategoryKeyByDivisionAndName(name, division){
    return categories.findIndex(category => {
      return category?.collection?.includes(division) && doesWordExistInString(category?.slugKeywords, name)
    })
  }

  function findIndexOfSizeGuideCategory(categoryKey) {
    const indexOfSizeGuideCategory = categories.findIndex(item => item.categoryKey === categoryKey)
    return indexOfSizeGuideCategory
  }

  function selectCategoryChange(categoryKey) {
    const newSelectedIndex = findIndexOfSizeGuideCategory(categoryKey)
    if (newSelectedIndex >= 0) {
      setSelectedIndexCategorySizeGuide(newSelectedIndex)
    }
  }

  function RenderTabs() {
    let tabTitlesToUse = [`WEB.LABELS.SIZE.GUIDE`, `WEB.LABELS.SIZE.HOW-TO-MEASURE`]
    if (shouldRenderHowToMeasureTab === false) {
      if (selectedTab !== TABLE_TAB) {
        setSelectedTab(TABLE_TAB)
      }
      tabTitlesToUse = [`WEB.LABELS.SIZE.GUIDE`]
    }

    return tabTitlesToUse.map((tabTitle, index) => {
      const activeClassName = index === selectedTab ? styles.tabsButtonActive : ``
      return (
        <button
          className={activeClassName}
          key={tabTitle}
          onClick={() => {setSelectedTab(index)}}
        >
          {I18n.t(tabTitle)}
        </button>
      )
    })
  }

  function RenderSelectedTab() {
    if (selectedTab === TABLE_TAB) {
      return (
        <ProductSizeGuidesVBTableTab
          categories={categories}
          selectCategoryChange={selectCategoryChange}
          selectedIndexCategorySizeGuide={selectedIndexCategorySizeGuide}
        />
      )
    } else if (selectedTab === HOW_TO_MEASURE_TAB) {
      return (
        <ProductSizeGuidesVBMeasureTab
          categories={categories}
          hasCategoryHowToMeasureData={hasCategoryHowToMeasureData}
          howToMeasureData={howToMeasureData}
          selectCategoryChange={selectCategoryChange}
          selectedIndexCategorySizeGuide={selectedIndexCategorySizeGuide}
        />
      )
    }
  }


  return (
    <div className={styles.sizeGuideModal}>
      <div className={styles.tabsContainer}>
        {RenderTabs()}
      </div>

      {RenderSelectedTab()}
    </div>
  )
}
