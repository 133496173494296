import React from 'react'
import { Provider } from 'react-redux'
import { Events } from 'design-system/services/events'
import { AlgoliaInsightEmitter } from 'design-system/vendors/AlgoliaInsights'
import { GoogleAnalyticsEmitter } from 'design-system/vendors/googleAnalytics'
import { KlaviyoAnalyticsEmitter } from 'design-system/vendors/Klaviyo'

import Maintenance from 'utils/maintenance'
import Dev from 'utils/dev'
import { tracking, isTracking } from 'utils/tracking'
import { iosViewportFix } from 'utils/iosViewportFix'
import { setHrefLangTags } from 'utils/hreflangTags'
import { storageMaintenance } from 'utils/storageMaintenance'
import { forter } from 'utils/forter'
import store from 'state/reducers'
import { favicon } from 'utils/favicon'
import { loadFonts } from 'utils/loadFonts'
import { Router } from './Router/Router'
import { Captcha } from 'components/Captcha'
import { AppError } from 'components/AppError'
import { getMetaOptions } from 'global-content/config'
import get from 'lodash/get'
import { klaviyoInitCallback } from 'services/klaviyo-setup'
import { getQueryParamHideKlaviyo } from 'utils/getQueryParamHideComponents'

// so hot-reload doesn't keep rerunning the effect
let loadedOnce = false

export const App = () => {
  React.useEffect(() => {
    if (!loadedOnce) {
      loadedOnce = true
      // laviniu depends on 'initialized' for an uptime script
      document.querySelector(`body`).className = `initialized`
      loadFonts()
      favicon(window.$COUNTRYFOLDER)
      iosViewportFix()
      setHrefLangTags()
      tracking()
      forter()
      storageMaintenance()

      const {
        account: appId,
        key: apiKey,
      } = getMetaOptions(`integrations.algolia`)

      Events.registerPayloadMiddleware(eventEmitterMiddlewareFn)
      AlgoliaInsightEmitter.init({
        appId,
        apiKey,
        userToken: window.$shoppingSessionId.value,
        enabled: isTracking(),
      })
      if (getQueryParamHideKlaviyo() === false) {
        KlaviyoAnalyticsEmitter.init({
          klaviyoCompanyId: getMetaOptions(`integrations.klaviyo.publicApiKey`),
          initCallbackFn: klaviyoInitCallback,
        })
      }
      Events.registerEventEmitters([
        AlgoliaInsightEmitter,
        GoogleAnalyticsEmitter,
        KlaviyoAnalyticsEmitter,
      ])

      window.$dev = new Dev()
      window.$maintenance = new Maintenance()
      window.$maintenance.init()

      // NOTE(Kevin): Set redux store into window for easy debugging using window.$store.getState()
      if (window.$ENV !== `production`) {
        window.$store = store
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <AppErrorBoundary>
        <Router />
        <Captcha />
      </AppErrorBoundary>
    </Provider>
  )
}

export class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      hasError: Boolean(props.error),
    }
  }

  static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true,
    }
  }

  componentDidCatch(error) {
    console.log(error)
  }

  render() {
    const {
      children,
    } = this.props

    const {
      hasError,
    } = this.state

    if (hasError) {
      return (
        <AppError error={this.state.error} />
      )
    }

    return children
  }
}

function getFullLanguage(languageCode) {
  try {
    const languageNames = new Intl.DisplayNames([`en`], {
      type: `language`,
    })
    return languageNames.of(languageCode)
  } catch (err) {
    return languageCode
  }
}

function eventEmitterMiddlewareFn(payload) {
  const reduxStore = store.getState()
  const userId = get(reduxStore, `account.userId`)
  const shoppingSessionId = get(window, `$shoppingSessionId.value`)
  const siteTag = getMetaOptions(`siteTag`)
  const currency = getMetaOptions(`currency.code`)
  const language = get(reduxStore, `language.active`)
  const fullLang = getFullLanguage(language)
  const middleware = {
    userId,
    shoppingSessionId,
    siteTag,
    currency,
    language,
    fullLang,
  }
  return {
    middleware,
    ...payload,
  }
}
