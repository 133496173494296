import uniq from 'lodash/uniq'

import {
  ADD_CART_ITEM,
  DETAILS_ADD_MESSAGE,
  DETAILS_PRELOAD,
  DETAILS_REMOVE_MESSAGE,
  GET_PRODUCT,
  GET_PRODUCT_CUSTOMISATION,
  POST_PRODUCT_CUSTOMIZATION,
  PRE_SELECT_PRODUCT_CUSTOMISATION,
  NO_PRODUCT,
  SET_PENDING_QUANTITY,
  UPDATE_SELECTED_OPTION,
  UPDATE_MISSING_LEVELS,
  VALIDATE_PRODUCT_CUSTOMISATION,
} from 'state/actions'
import { toDetailProduct } from 'models/product'
import { STATUSCODE_ADD_CART_FAILURE, AVAILABILITY_NOTAVAILABLE } from 'utils/constants'
import set from 'lodash/set'
import {
  getSelectedSlugs,
  getOptionValues,
} from 'state/utils/details'

const initialCustomisationState = {
  loading: true,
  enabled: false,
  options: {},
  invalidSkus: [],
  selectedOptions: {},
  validation: {},
  customisedImage: {
    error: false,
    loading: true,
    sources: {},
  },
}

const initialState = {
  infoMessages: [],
  levelOptions: {},
  loadingProduct: true,
  missingLevels: [],
  notFound: false,
  pendingQuantity: 1,
  product: undefined,
  plpInfo: {
    queryID: undefined,
    objectID: undefined,
    indexName: undefined,
    filters: [],
  },
  customisation: initialCustomisationState,
  selectedSlugs: {},
  skuOptions: {},
  promotions: {},
}

const details = (state = initialState, action) => {
  switch (action.type) {
  case `${ADD_CART_ITEM}_REJECTED`:
    return {
      ...state,
      infoMessages: uniq([...state.infoMessages, STATUSCODE_ADD_CART_FAILURE]),
    }
  case DETAILS_ADD_MESSAGE: {
    return {
      ...state,
      infoMessages: uniq([...state.infoMessages, action.payload]),
    }
  }
  case DETAILS_REMOVE_MESSAGE: {
    const infoMessages = state.infoMessages.filter(messageCode => messageCode !== action.payload)

    return {
      ...state,
      infoMessages,
    }
  }
  case SET_PENDING_QUANTITY: {
    return {
      ...state,
      pendingQuantity: action.payload,
    }
  }
  case UPDATE_SELECTED_OPTION: {
    const selectedSlugs = {
      ...state.selectedSlugs,
      ...action.payload.options,
    }
    const isValid = action.payload.isValid
    const levelOptions = getOptionValues(state.product, selectedSlugs)
    const skuOptions = getSkuOptions(levelOptions, selectedSlugs, isValid)
    const promotions = action.payload.getPromotions(state.product, skuOptions)

    // Note: When we change sizeType, we reset the currently selected size option
    if (action.payload.options.sizeType) {
      delete selectedSlugs.size
    }

    return {
      ...state,
      infoMessages: [],
      levelOptions,
      selectedSlugs,
      skuOptions,
      promotions,
    }
  }
  case UPDATE_MISSING_LEVELS: {
    return {
      ...state,
      missingLevels: action.payload,
    }
  }
  case `${GET_PRODUCT}_PENDING`: {
    return {
      ...state,
      customisation: initialCustomisationState,
      loadingProduct: true,
      notFound: false,
    }
  }
  case `${GET_PRODUCT}_FULFILLED`: {
    // note to self: this is product api payload
    const product = toDetailProduct(action.payload.product)
    const selectedSlugs = getSelectedSlugs(product, action.payload.search)
    const levelOptions = getOptionValues(product, selectedSlugs)
    const skuOptions = getSkuOptions(levelOptions, selectedSlugs)
    const promotions = action.payload.getPromotions(action.payload.product, skuOptions)

    return {
      ...state,
      infoMessages: [],
      loadingProduct: false,
      levelOptions,
      notFound: false,
      pendingQuantity: 1,
      product,
      selectedSlugs,
      skuOptions,
      promotions,
    }
  }
  case `${GET_PRODUCT_CUSTOMISATION}_FULFILLED`: {
    return {
      ...state,
      customisation: {
        ...state.customisation,
        ...action.payload,
        loading: false,
        customizedImage: {
          error: false,
          loading: true,
          sources: {},
        },
        enabled: action.payload.hasPreview,
      },
    }
  }
  case `${GET_PRODUCT_CUSTOMISATION}_REJECTED`: {
    return {
      ...state,
      customisation: {
        ...state.customisation,
        loading: false,
        enabled: false,
      },
    }
  }
  case`${POST_PRODUCT_CUSTOMIZATION}_REJECTED`: {
    return {
      ...state,
      customisation: {
        ...state.customisation,
        customisedImage: {
          ...state.customisation.customisedImage,
          error: true,
          loading: false,
        },
      },
    }
  }
  case `${POST_PRODUCT_CUSTOMIZATION}_PENDING`: {
    return {
      ...state,
      customisation: {
        ...state.customisation,
        customisedImage: {
          ...state.customisation.customisedImage,
          error: false,
          loading: true,
        },
      },
    }
  }
  case `${POST_PRODUCT_CUSTOMIZATION}_FULFILLED`: {
    if (action.payload.error) {
      const errors = action.payload.details.errors
      Object.entries(errors).forEach(([key, value]) => {
        set(state, `customisation.options.${key}.errors`, value)
      })
      return {
        ...state,
        customisation: {
          ...state.customisation,
          customisedImage: {
            error: true,
            loading: false,
          },
        },
      }
    }
    return {
      ...state,
      customisation: {
        ...state.customisation,
        customisedImage: {
          error: false,
          loading: false,
          sources: action.payload.previewImage,
        },
      },
    }
  }
  case PRE_SELECT_PRODUCT_CUSTOMISATION: {
    const {
      id,
      value,
    } = action.payload
    Object.keys(state.customisation.options).forEach((key) => {
      set(state, `customisation.options.${key}.errors`, [])
    })
    set(state, `customisation.selectedOptions.${id}`, value)
    return {
      ...state,
      customisation: {
        ...state.customisation,
        selectedOptions: {
          ...state.customisation.selectedOptions,
          [id]: value,
        },
      },
    }
  }
  case VALIDATE_PRODUCT_CUSTOMISATION: {
    const {
      errors,
    } = action.payload
    const options = Object.entries(state.customisation.options).reduce((acc, [key, value]) => {
      if (errors[key]) {
        acc[key] = {
          ...value,
          errors: errors[key],
        }
        return acc
      }
      acc[key] = value
      return acc
    }, {})
    return {
      ...state,
      customisation: {
        ...state.customisation,
        options,
      },
    }
  }
  case DETAILS_PRELOAD: {
    const {
      indexName,
      product,
      selectedSlugs,
      filters,
      queryID,
    } = action.payload

    return {
      ...state,
      infoMessages: [],
      loadingProduct: true,
      notFound: false,
      pendingQuantity: 1,
      levelOptions: {
        color: formatPreloadOptions(product.options, product.id),
      },
      customisation: initialCustomisationState,
      plpInfo: {
        objectID: product.objectID,
        queryID,
        indexName,
        filters,
      },
      skuOptions: {
        availability: product.availability,
        price: product.price,
      },
      product,
      selectedSlugs,
    }
  }
  case NO_PRODUCT:
  case `${GET_PRODUCT}_REJECTED`:
    return {
      ...initialState,
      loadingProduct: false,
      notFound: true,
    }
  default:
    return state
  }
}

function formatPreloadOptions(options, productId) {
  // add productSlug to color options
  return options.map(option => ({
    ...option,
    productSlug: `${productId}.${option.slug}`,
  }))
}

function getSkuOptions(levelOptions, selectedSlugs, isValid) {
  let skuOptions = {
    price: {
      list: {},
      sale: {},
    },
  }

  for (const slug in selectedSlugs) {
    skuOptions = {
      ...skuOptions,
      ...(levelOptions[slug].find(option => selectedSlugs[slug] === option.slug)),
      ...(isValid === false ? { availability: AVAILABILITY_NOTAVAILABLE } : {}),
      options: selectedSlugs,
    }
  }

  return skuOptions
}

export default details
