import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  loadAddressLayouts,
  loadBadges,
  loadFooter,
  loadFiltersPullout,
  loadMenuPullout,
  loadSplashModal,
  loadSupportNav,
  loadCountryNavigation,
  loadAddressUnableToShip,
  loadInterestGroups,
} from 'state/actions/content'

export function useContent(key) {
  const dispatch = useDispatch()
  const activeLanguage = useSelector(state => state.language.active)
  const content = useSelector(state => state.content[key]?.[activeLanguage])

  const actionMap = {
    addressLayouts: loadAddressLayouts,
    badges: loadBadges,
    footer: loadFooter,
    menuPullout: loadMenuPullout,
    filtersPullout: loadFiltersPullout,
    splashModal: loadSplashModal,
    supportNav: loadSupportNav,
    countryNavigation: loadCountryNavigation,
    addressUnableToShip: loadAddressUnableToShip,
    interestGroups: loadInterestGroups,
  }

  React.useEffect(() => {
    if (!content) {
      dispatch(actionMap[key]?.())
    }
  }, [key])

  return content
}
