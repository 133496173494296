import queryString from 'query-string'
import {
  QUERY_PARAM_HIDE_ALL_POPUP,
  QUERY_PARAM_HIDE_MODAL,
  QUERY_PARAM_HIDE_COOKIES,
  QUERY_PARAM_HIDE_KLAVIYO,
} from 'utils/constants'

function getQueryParamHideComponents(queryParam) {
  const queryStringValues = queryString.parse(window.location.search)
  let shouldHideComponent = false
  if (queryStringValues && Object.keys(queryStringValues).includes(queryParam)) {
    shouldHideComponent = Boolean(queryStringValues[queryParam])
  }
  return shouldHideComponent
}

export function getQueryParamHideAllPopup() {
  return getQueryParamHideComponents(QUERY_PARAM_HIDE_ALL_POPUP)
}

export function getQueryParamHideModal() {
  return getQueryParamHideAllPopup() || getQueryParamHideComponents(QUERY_PARAM_HIDE_MODAL)
}

export function getQueryParamHideCookieBanner() {
  return getQueryParamHideAllPopup() || getQueryParamHideComponents(QUERY_PARAM_HIDE_COOKIES)
}

// Note: This will hide Klaviyo popups and embedded form components
export function getQueryParamHideKlaviyo() {
  return getQueryParamHideAllPopup() || getQueryParamHideComponents(QUERY_PARAM_HIDE_KLAVIYO)
}
