import get from 'lodash/get'

export function checkExceptionsList(value) {
  let exceptionList = {}
  try {
    exceptionList = JSON.parse(window.$EXCEPTION_LIST)
  } catch(e) {
    exceptionList = {}
  }

  return get(exceptionList, value, value)
}
