
import { unversionedStorage } from 'utils/storage'
export const storageMaintenance = () => {
  // We are removing localStorage keys that are not used but overflow local storage
  // https://localised.sentry.io/issues/5368112459/events/224f3140bb304652a7d062a70f075646/?project=1842893
  unversionedStorage.remove(`apollo-cache-persist-jp_ja`)
  unversionedStorage.remove(`apollo-cache-persist-default`)
  unversionedStorage.remove(`snowplowOutQueue_magento_store_events_magento-storefront-js_post2`)
  unversionedStorage.remove(`apollo-cache-persist-jp_ja`)
}
