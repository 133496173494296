import React from 'react'
import { useLocation } from 'react-router-dom'
import{ useLocalStorageChange } from 'hooks/useLocalStorageChange'
import { findItemToRender } from 'hooks/useItemToRender'
import { useModal } from 'hooks/useModal'
import { useContent } from 'hooks/useContent'
import { unversionedStorage } from 'utils/storage'
import { MediaQuery } from 'components/MediaQuery'
import { Factory } from 'components/Factory'
import { Layout } from 'components/Layout'
import { isNumber, invoke } from 'lodash'
import { getQueryParamHideModal } from 'utils/getQueryParamHideComponents'

export const ModalSplash = () => {
  const domEl = document.getElementById(`modal`)

  const {
    hide,
    isVisible,
    show,
    RenderModal,
  } = useModal({
    onHide: incrementView,
  })

  const { pathname } = useLocation()
  const splashModal = useContent(`splashModal`)
  const hasBeenViewed = useLocalStorageChange(`modalViewed`, 0) >= 1
  const splashModalToRender = React.useMemo(getItems, [hasBeenViewed, pathname, splashModal])

  React.useEffect(manageEvents, [isVisible])
  React.useEffect(toggle, [hasBeenViewed, splashModalToRender])

  if (isVisible) {
    return (
      <RenderModal>
        <MediaQuery items={splashModalToRender}>
          {SplashModalComponent}
        </MediaQuery>
      </RenderModal>
    )
  }

  return null

  function cleanup() {
    invoke(domEl, `removeEventListener`, `click`, handleClick)
  }

  function formatArray(xs) {
    return Array.isArray(xs) ? xs : []
  }

  function getItems() {
    if (hasBeenViewed) {
      return []
    }

    return formatArray(findItemToRender(
      formatArray(splashModal?.items),
      pathname
    )?.items)
  }

  function handleClick(e) {
    return handleContentClick(e, hide)
  }

  function manageEvents() {
    if (isVisible) {
      invoke(domEl, `addEventListener`, `click`, handleClick)
    } else {
      cleanup()
    }

    return cleanup
  }

  function toggle() {
    const shouldHideModal = getQueryParamHideModal()
    if (!hasBeenViewed && splashModalToRender.length && shouldHideModal === false) {
      show()
    }
  }
}

const SplashModalComponent = ({
  items,
  ...rest
}) => {
  return (
    <Layout
      {...rest}
      data-testid="splash-modal"
    >
      <Factory items={items} />
    </Layout>
  )
}

export function incrementView(num) {
  const newValue = isNumber(num) ? num : 1
  const event = new StorageEvent(`storage`, {
    key: `modalViewed`,
    newValue,
  })

  unversionedStorage.set(`modalViewed`, newValue)
  window.dispatchEvent(event)
}

function handleContentClick(e, hide) {
  const path = e.composedPath() // need this so that solution works across all supported browsers
  const {
    isCloseableTarget,
    isTargetNewWindow,
  } = determineTargetAction(path)
  if (isCloseableTarget) {
    // move to the back of the callstack so the event can bubble up correctly
    // and be handled by React's synthetic events
    setTimeout(() => {
      hide()
    }, 0)
  }
  if (isTargetNewWindow) {
    incrementView()
    hide()
  }
}

function determineTargetAction(path) {
  // could add `BUTTON` to this, however carousel arrows and email signup buttons would close the modal
  const closeableTargets = [`A`]
  let isCloseableTarget = false
  let isTargetNewWindow = false

  for (let i = 0; i < path?.length; i++) {
    if (closeableTargets.includes(path[i].nodeName)) {
      if (path[i].getAttribute(`target`) !== `_blank`) {
        isCloseableTarget = true
      } else {
        isTargetNewWindow = true
      }
      break
    }
  }

  return { isCloseableTarget, isTargetNewWindow }
}
