import { getHost, getMetaOptions } from 'global-content/config'

// API ROUTES
export const ACCOUNT_SESSION_URL = `/api/session`
export const BASIC_SESSION_URL = `/api/basic-session`
export const UPDATE_PROPERTIES_URL = `${ACCOUNT_SESSION_URL}/updateProperties`

export const CONSUMER_PROPERTIES_URL = `/api/checkout/consumer`
export const PAYMENT_SESSION_URL = `/api/checkout/paymentSession`
export const CHECKOUT_URL = `/api/checkout/payment`

export const TERRITORY_URL = `/api/territory`
export const SERVER_TIME_URL = `/api/get-time`

export const ADDRESS_URL = `/api/v1/addresses`
export const ACCOUNT_PAYMENT_METHODS_URL = `/api/v1/account/payments`

export const PRODUCT_URL_STATELESS = `/api/product/s`
export const CART_URL = `/api/cart`
export const VOUCHER_URL = `/api/cart/voucher`
export const RECREATE_CART_URL = `/api/cart/recreateCart`
export const CAMPAIGNS_URL = `/api/cart/promotions`
export const CART_ADDRESS_URL = `/api/cart/address`
export const CART_CALCULTE_TAX = `/api/cart/calculate-tax`

export const SHIPPING_URL = `/api/shipping`

export const ORDER_HISTORY_URL = `/api/order/history`
export const ORDER_DETAILS_STATEFUL_URL = `/api/order/`
export const ORDER_DETAILS_STATELESS_URL = `/api/order/s/myOrder`
export const ORDER_DETAILS_STATELESS_URL_POST = `/api/order/s/query`

export const EMAIL_SUBSCRIBE_URL = `/api/marketing/subscribe`
export const EMAIL_REMOVE_URL = `/api/marketing/remove`


export const SESSION_ID_NAME = `x-amz-cf-id`

export function baseUrl() {
  const countryFolder = getMetaOptions(`countryFolder`)
  if (countryFolder) {
    return `https://${getHost().replace(countryFolder, ``)}`
  }
  return `https://${getHost()}`
}
