import { getMetaOptions } from 'global-content/config'
import { getLanguage } from 'utils'
import queryString from 'query-string'

function getQuery(params) {
  const siteTag = getMetaOptions(`siteTag`)
  const language = getLanguage()
  return queryString.stringify({ lang: language, siteTag, ...params })
}

export function fetchAtmos({
  path,
  params,
  options = {},
}) {
  return fetch(`/atmos/${path}?${getQuery(params)}`, options)
    .then(response => response.json())
}

export function healthCheckAtmos() {
  return fetchAtmos({ path: `/v1/healthcheck` })
}
