// we need a wildcard reference
// so this is picked up by the collectWebLabels function
// and they are added to lithos' webLabels file

export const webLabels = {
  sizeGuideLabels: `WEB.LABELS.SIZE.*`,
}

export const CATAGORY_PAGE_ID_RANK = 10000000
export const BRAND_RANK = 50000
export const CATEGORY_COLLECTION_RANK = 1000
export const KEYWORD_RANK = 1
